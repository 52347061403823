import React from 'react';
import PropTypes from "prop-types";

import "./pdf_button.css"

const PDFButton = (props) => {
	return (
		<a href={props.document_url} title={props.document_title} target="_blank" className="pdf-button">
			<img src={props.image_source}/>
			{props.text}
		</a>
	)
}

PDFButton.defaultProps = {
	text: "",
	document_name: "document.pdf",
	document_title: "PDF Document",
	document_url: "",
	image_source: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png"
}

PDFButton.PropTypes = {
	text: PropTypes.string,
	document_name: PropTypes.string,
	document_title: PropTypes.string,
	document_url: PropTypes.string,
	image_source: PropTypes.string
}

export default PDFButton