import React, { useState, useRef } from 'react'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import { Box, Modal, Fade, Backdrop, Typography } from '@mui/material'

import Navbar from '../components/navbar'
import Accordion from '../components/accordion'
import Includes from '../components/includes'
import Excludes from '../components/excludes'
import Review from '../components/review'
import Article from '../components/article'
import FAQ from '../components/f-a-q'
import './home.css'
import SocialLikes from '../components/social-likes'
import Activities, { ActivitiesType } from '../components/activities'
import ApplicationAppearance from '../components/application-appearance'
import Partners from '../components/partners.js'
import PDFButton from '../components/pdf_button'
import { Facebook } from '@mui/icons-material'

export function handleScroll (event) { 
  let id = event.target.id.replace("_link", "")
  console.log("id: " + id)
  scrollTo(id)
}

export const scrollTo = (id) => document.getElementById(id).scrollIntoView({behavior: 'smooth', block: 'start'});

const Home = (props) => {
  const [impressumOpen, setImpressumOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const handleModalImpressumOpen = () => setImpressumOpen(true);
  const handleModalImpressumClose = () => setImpressumOpen(false);
  const handleModalPrivacyPolicyOpen = () => setPrivacyPolicyOpen(true);
  const handleModalPrivacyPolicyClose = () => setPrivacyPolicyOpen(false);
  const handleModalContactOpen = () => setContactOpen(true);
  const handleModalContactClose = () => setContactOpen(false);

  const [selectedFeature, setSelectedFeature] = useState(ActivitiesType.BOOK_PUBLISHING);

  function handleFeatureSelection(event) {
    let _selectedActivity = ActivitiesType.BOOK_PUBLISHING 
    switch (event.target.id) {
      case "book_publish_link": _selectedActivity = ActivitiesType.BOOK_PUBLISHING; break;
      case "quality_control_management_link": _selectedActivity = ActivitiesType.QUALITY_MANAGEMENT_SYSTEM; break;
      case "personal_data_protection_link": _selectedActivity = ActivitiesType.PERSONAL_DATA_PROTECTION; break;
    }
    setSelectedFeature(_selectedActivity)
    scrollTo("activities")
    // const features = document.querySelector("#activities")
    // features.scrollIntoView({behavior: 'smooth', block: 'start'})
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '95%',
    minWidth: 360,
    width: '70%',
    maxHeight: '80%',
    overflowY: 'auto',
    backgroundColor: 'rgba(0,0,0,0.8)',
    backdropFilter: 'blur(10px)',
    color: '#ffffff',
    borderRadius: '10px',
    p: 4,
  };

  const impressumBox = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: "20px",
  };

  return (
    <div className="home-container">


      {/* IMPRESSUM MODAL */}
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={impressumOpen}
      onClose={handleModalImpressumClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={impressumOpen}>
        <Box sx={modalStyle}>
          <Typography id="transition-modal-title" variant="h4" component="h2">
            Impresszum
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            <Typography variant='h5' align='center'>Honlap tartalomszolgáltató:</Typography>
            <Box className='impressumModalItem' sx={impressumBox} >
              <img className="impressumModalImage" width="50%" height="100px" src="/assets/dak%C3%A1r%C3%B3.svg"/>
              <Typography width="50%">
                DAKÁRÓ Kft.<br />
                1144 Budapest, Ormánság utca 4/A 8/197<br />
                info@dakaro.hu<br />
                +36 30 443 5060<br />
                <br />
              </Typography>
            </Box>
            <Typography variant='h5' align='center'>Honlap fejlesztője:</Typography>
            <Box className='impressumModalItem' sx={impressumBox} >
              <img className="impressumModalImage" width="50%" height="100px" src="/assets/rapthor_logo.png"/>
              <Typography width="50%">
                Dalnoki Róbert<br />
                <a href="mailto:dalnokirobert91@gmail.com?subject=Aj%C3%A1nlatk%C3%A9r%C3%A9s&body=%C3%9Cdv%C3%B6zl%C3%B6m!%0A%0AA%20dakaro.hu%20weboldal%C3%A1n%20jutottam%20hozz%C3%A1%20az%20email%20c%C3%ADm%C3%A9hez.%0A%0ASzeretn%C3%A9k%20a%20v%C3%A1llalatomnak%2Fegyes%C3%BCletemnek%20...">dalnokirobert91@gmail.com</a><br />
                <a href="tel:+36203838385">+36 20 38 38 38 5</a><br />
                <br />
              </Typography>
            </Box>
            <Typography variant='h5' align='center'>Tárhely szolgáltató:</Typography>
            <Box className='impressumModalItem' sx={impressumBox} >
              <img className="impressumModalImage" width="50%" height="100px" src="/assets/forpsi_logo.png"/>
              <Typography width="50%">
                BlazeArts Kft. (Forpsi)<br />
                info@forpsi.hu<br />
                +36 1 610 5506
              </Typography>
            </Box>
          </Typography>
        </Box>
      </Fade>
    </Modal>


    {/* PRIVACY POLICY MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={privacyPolicyOpen}
        onClose={handleModalPrivacyPolicyClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={privacyPolicyOpen}>
          <Box sx={modalStyle}>
            <Typography id="transition-modal-title" variant="h4" component="h2">
              Adatkezelési tájékoztató
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              A DAKÁRÓ Kft. HONLAPJA (www.dakaro.hu/) mint weboldal használatának ténye azt jelenti, hogy Ön tudomásul vette a 
              honlapon található weboldal adatkezelési tájékoztatóba foglalt feltételrendszerünket. Az üzemeltető DAKÁRÓ Kft. a 
              látogatók, és a partnereink által megadott személyes adatokat bizalmasan kezeli, harmadik félnek azokat, a 
              jogszabályokban előírt esetektől eltekintve, hozzájárulásuk nélkül nem adja át. Jelen weboldal látogatóinak és 
              részünkre emailt küldők személyes adatai az EU 2016/679. számú Általános Adatvédelmi Rendelete ("GDPR") 6. cikk 
              1.) bekezdés b) pontja alapján kerülnek kezelésre. A DAKÁRÓ Kft. weboldalán található személyes adatok tájékoztató 
              jellegűek. A honlapon található bármilyen személyes adat, vagy a DAKÁRÓ Kft-re vonatkozó jellemző előzetes engedély 
              nélküli felhasználása, publikálása, címlistába helyezése jogsértést valósít meg. A HONLAP teljes tartalma az 
              1999. évi LXXVI., a szerzői jogról szóló törvényben foglaltak szerint védelem alatt áll, figyelemmel az EU szerzői 
              jogi irányelvének 11. cikkére is. Folyamatos fejlesztéssel arra törekszünk, hogy oldalunkat a mindenkori legmagasabb 
              színvonalon tartsuk. Az esetleges technikai fennakadásokból eredő károkért felelősséget nem vállalunk. Mint az oldal 
              üzemeltetője, mindennemű változás - ideértve az esetleges szünetelést és megszüntetést is - jogát fenntartjuk. 
              Lehetőségeink szerint mindent elkövetünk azért, hogy a honlapunkon lévő adatok pontosak és aktuálisak legyenek, de 
              az esetleges pontatlan adatközlésekből eredő kárért való felelősséget kifejezetten kizárjuk. A DAKÁRÓ Kft. HONLAPJA 
              számos linket (kapcsolódási pontot) tartalmaz, amelyek az internet más pontjaira juttathatják Önt. Ezen weboldalak 
              tartalmáért és szolgáltatóik adat-, valamint információvédelmi gyakorlatáért a DAKÁRÓ Kft. felelősséget nem vállal. 
              A DAKÁRÓ Kft. HONLAPJÁT más honlapokhoz csatolni csak formai és tartalmi változtatás nélkül lehet, csak és abban az 
              esetben, ha az nem szolgál hirdetési vagy kereskedelmi célokat. Amennyiben felmerül oldalunk más oldalhoz való 
              kapcsolása, igényüket és kapcsolattartójuk nevét, elérhetőségét kérjük a következő címre küldjék:  <a href="mailto:info@dakaro.hu">info@dakaro.hu</a>.
            </Typography>
            <hr />
            <hr />
            <Typography variant="h5" component="h3">ADATVÉDELEM</Typography>
            <hr />
            <Typography>
              A DAKÁRÓ Kiadó, Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság - (röviden: DAKÁRÓ Kft.) (a továbbiakban: adatkezelő) 
              magára nézve kötelezőnek ismeri el a tájékoztató(k)ban foglalt szabályokat, amelyeket a hatályos jogi szabályozás alapján alakított ki.
            </Typography>
            <Typography>
              Az  adatkezelési és adatvédelmi tájékoztatásokban foglalt adatkezelések mindegyike tekintetében a DAKÁRÓ Kft. (Székhely: 1144. Budapest, Ormánság u. 4/a. 8/197. honlap: <a href="http://www.dakaro.hu/">https://www.dakaro.hu</a>) az adatkezelő.
            </Typography>
            <hr />
            <Typography variant='h6' component="h4">Elérhetőségeink:</Typography>
            <hr />
            <Typography>
              <b>levélben</b>: DAKÁRÓ Kft. székhelyére címzett levélben,<br />
              <b>e-mailben</b>: az <a href="mailto:info@dakaro.hu">info@dakaro.hu</a> e-mail-címen, nevének és elérhetőségének megadásával  személyesen: a DAKÁRÓ Kft. székhelyén.
            </Typography>
            <hr />
            <Typography>
              Adatkezelési eljárásunkkal kapcsolatos panasszal a DAKÁRÓ Kft. adatvédelmi felelőséhez fordulhat az alábbi elérhetőségen: <a href="mailto:adatvedelem@dakaro.hu">adatvedelem@dakaro.hu</a>
            </Typography>
            <hr />
            <Typography>
              Amennyiben nem intézkedünk a kérelmére, vagy intézkedésünket nem fogadja el, úgy jogorvoslattal élhet az alábbi Hatóságnál:
            </Typography>
            <hr />
            <Typography>
                <b>Nemzeti Adatvédelmi és Információszabadság Hatóság (NAIH)</b> <br />
                <br />
                <b>Székhely</b>: 1125. Budapest, Szilágyi Erzsébet fasor 22/c<br />
                <b>Levelezési cím</b>: 1530 Budapest, Pf: 5<br />
                <b>Telefon</b>: +36 1 391 1400<br />
                <b>E-mail</b>: ugyfelszolgalat@naih.hu<br />
                <b>Honlap</b>: www.naih.hu<br />
            </Typography>
            <hr />
            <Typography>
              vagy jogsértés esetén a lakóhelye vagy tartózkodási helye szerinti törvényszékhez fordulhat. 
            </Typography>
            <hr />
            <Typography>
              A DAKÁRÓ Kft (a továbbiakban: adatkezelő) magára nézve kötelezőnek ismeri el az alábbi adatkezelési tájékoztató(k)ban foglalt szabályokat, amelyeket a hatályos jogi szabályozás alapján alakított ki:
            </Typography>
            <hr />
            <PDFButton 
              document_name="Weboldal adatkezelési tájékoztató" 
              document_url="https://www.odoo.dakaro.hu/web/content/1151?unique=bda0aa2b1fa2f787d32e11bf487a2daa00791419" 
              document_title="DAKÁRÓ weboldal adatkezelési tajékoztató_v_1_2022_11_23.pdf" 
              text="Weboldal adatkezelési tájékoztató"/>
          </Box>
        </Fade>
      </Modal>


      {/* CONTACT MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={contactOpen}
        onClose={handleModalContactClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={contactOpen}>
          <Box sx={modalStyle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Kapcsolat
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <Box className='impressumModalItem' sx={impressumBox} >
                <img className="impressumModalImage" width="50%" height="100px" src="/assets/dak%C3%A1r%C3%B3.svg"/>
                <Typography width="50%">
                  DAKÁRÓ Kft.<br />
                  1144 Budapest, Ormánság utca 4/A 8/197<br />
                  info@dakaro.hu<br />
                  +36 30 443 5060<br />
                  <br />
                </Typography>
              </Box>
            </Typography>
          </Box>
        </Fade>
      </Modal>


      <Helmet>
        <title>DAKÁRÓ web</title>
        <meta property="og:title" content="DAKÁRÓ web" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name"></Navbar>
      {/* TODO For the news */}
      <section className="home-section" id="news">
        <div className="home-hero">
          <div className="home-content">
            <main className="home-main">
              <header className="home-header">
                <h1 className="home-heading">
                  Üdvözöljük megújult honlapunkon!
                </h1>
                <span className="home-caption">
                  Oldalunkat folyamatosan újítjuk és bővítjük, azonban még 
                  néhány összetevő még fejlesztés alatt áll.<br/>
                  <br/>
                  Kérem tekintse meg könyv katalógusunkat vagy ismerje 
                  meg tevékenységeinket
                </span>
              </header>
              <div className="home-buttons">
                <div className="home-get-started button">
                  <span className="home-text">Könyv Katalógus</span>
                </div>
                <div onClick={handleScroll} id="activities_link"  className="home-get-started1 button">
                  <span className="home-text01">Tevékenységeink</span>
                </div>
              </div>
            </main>
            {/*<SocialLikes />*/}
          </div>
          <div className="home-image03">
            <img
              alt="image"
              src="/assets/new_book_promo_tavoli_feny.png"
              className="home-image04"
            />
          </div>
          <div className="home-image05">
            <img
              alt="image"
              src="/assets/new_book_promo_tavoli_feny.png"
              className="home-image06"
            />
          </div>
        </div>
      </section>
      {/* TODO A simple tabs section */}
      <Activities id="activities" selectedFeature={selectedFeature}/>
      {/* TODO It's cool for a demo */}
      {/* <ApplicationAppearance /> */}
      <Partners id="partners" />
      {/* DESCRIBE PROMOTION}
      <section className="home-section07">
        <div className="home-note3">
          <div className="home-image13">
            <img
              alt="image"
              src="/assets/iphone%2014%20pro%20max-1200w.png"
              className="home-image14"
            />
          </div>
          <div className="home-content4">
            <div className="home-caption4">
              <span className="section-head">Tempor incididunt</span>
            </div>
            <div className="home-heading07">
              <div className="home-header04">
                <h2 className="section-heading">
                  Tips to get care, answers and advice faster
                </h2>
              </div>
              <Accordion rootClassName="accordion-root-class-name"></Accordion>
            </div>
          </div>
        </div>
      </section>
      {*/}
      {/* BANNER WITH 2 BUTTONS}
      <section className="home-section09">
        <div className="home-cube">
          <div className="home-top side"></div>
          <div className="home-front side"></div>
          <div className="home-left1 side"></div>
        </div>
        <main className="home-banner">
          <div className="home-header05">
            <h2 className="section-heading">
              Planical makes online doctor visits easier
            </h2>
            <p className="home-description section-description">
              Lorem ipsum dolor sit amet!
            </p>
          </div>
          <div className="home-buttons1">
            <div className="home-get-started4 button">
              <span className="home-text22">Get started</span>
            </div>
            <div className="home-book-demo button">
              <span className="home-text23">Book a demo</span>
            </div>
          </div>
        </main>
      </section>
      {*/}
      {/* PRICING}
      <section className="home-section10">
        <div className="home-cube1">
          <div className="home-top1 side"></div>
          <div className="home-front1 side"></div>
          <div className="home-left2 side"></div>
        </div>
        <main className="home-pricing">
          <header className="home-header06">
            <header className="home-left3">
              <span className="section-head">Pricing</span>
              <h2 className="section-heading home-heading10">
                Start small, think big
              </h2>
            </header>
            <div className="home-right1">
              <p className="home-paragraph4 section-description">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam.
              </p>
            </div>
          </header>
          <div className="home-plans-container">
            <div className="home-switch">
              <div className="switch">
                <label className="home-text24">Monthly</label>
              </div>
              <div className="home-switch2 switch">
                <label className="home-text25">Yearly</label>
              </div>
            </div>
            <main className="home-plans">
              <div className="home-plan">
                <div className="home-details">
                  <div className="home-header07">
                    <label className="home-name">Basic</label>
                    <div className="home-pricing1">
                      <h1 className="home-price">$9</h1>
                      <span className="home-duration">/mo</span>
                    </div>
                  </div>
                  <p className="home-description1">
                    Good for sed quia consequuntur magni dolores eos qui
                    ratione.
                  </p>
                </div>
                <div className="home-buy-details">
                  <div className="home-buy button">
                    <span className="home-text26">
                      <span>Start Basic</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="home-features1">
                    <span className="home-heading11">You will get</span>
                    <div className="home-list">
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Excludes rootClassName="excludes-root-class-name"></Excludes>
                      <Excludes rootClassName="excludes-root-class-name"></Excludes>
                      <Excludes rootClassName="excludes-root-class-name"></Excludes>
                      <Excludes rootClassName="excludes-root-class-name"></Excludes>
                      <Excludes rootClassName="excludes-root-class-name"></Excludes>
                      <Excludes rootClassName="excludes-root-class-name"></Excludes>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-plan1">
                <div className="home-details1">
                  <div className="home-header08">
                    <label className="home-name1">Professional</label>
                    <div className="home-pricing2">
                      <h1 className="home-price1">$15</h1>
                      <span className="home-duration1">/mo</span>
                    </div>
                  </div>
                  <p className="home-description2">
                    Good for sed quia consequuntur magni dolores eos qui
                    ratione.
                  </p>
                </div>
                <div className="home-buy-details1">
                  <div className="home-buy1 button">
                    <span className="home-text29">
                      <span>Start Professional</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="home-features2">
                    <span className="home-heading12">You will get</span>
                    <div className="home-list1">
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Excludes rootClassName="excludes-root-class-name"></Excludes>
                      <Excludes rootClassName="excludes-root-class-name"></Excludes>
                      <Excludes rootClassName="excludes-root-class-name"></Excludes>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-plan2">
                <div className="home-details2">
                  <div className="home-header09">
                    <label className="home-name2">Enterprise</label>
                    <div className="home-pricing3">
                      <span className="home-price2">$99</span>
                      <span className="home-duration2">/mo</span>
                    </div>
                  </div>
                  <p className="home-description3">
                    Good for sed quia consequuntur magni dolores eos qui
                    ratione.
                  </p>
                </div>
                <div className="home-buy-details2">
                  <div className="home-buy2 button">
                    <span className="home-text32">
                      <span>Start Enterprise</span>
                      <br></br>
                    </span>
                  </div>
                  <div className="home-features3">
                    <span className="home-heading13">You will get</span>
                    <div className="home-list2">
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                      <Includes rootClassName="includes-root-class-name"></Includes>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </main>
        <div className="home-help">
          <span className="home-text35">
            <span>Need any help?</span>
            <br></br>
          </span>
          <div className="home-contact-support">
            <p className="home-text38">Contact support -&gt;</p>
          </div>
        </div>
      </section>
      {*/}
      {/* Reviews}
      <section className="home-section12">
        <header className="home-header10">
          <header className="home-left4">
            <span className="section-head">Tempor incididunt</span>
            <h2 className="home-heading14 section-heading">
              What users say about us
            </h2>
          </header>
          <div className="home-right2">
            <p className="home-paragraph5 section-description">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam.
            </p>
          </div>
        </header>
        <main className="home-cards1">
          <div className="home-container1">
            <Review rootClassName="review-root-class-name"></Review>
            <Review
              Quote="“Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.\u2028\u2028Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.”"
              rootClassName="review-root-class-name"
            ></Review>
          </div>
          <div className="home-container2">
            <Review
              Quote="“Illum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”"
              rootClassName="review-root-class-name"
            ></Review>
            <Review
              Quote="“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.”"
              rootClassName="review-root-class-name"
            ></Review>
          </div>
          <div className="home-container3">
            <Review
              Quote="“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.”"
              rootClassName="review-root-class-name"
            ></Review>
            <Review
              Quote="“Doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.”"
              rootClassName="review-root-class-name"
            ></Review>
          </div>
        </main>
        <div className="home-view-more">
          <p className="home-text39">View more</p>
        </div>
      </section>
      {*/}
      {/* Articles about us}
      <section className="home-section14">
        <header className="home-header11">
          <span className="section-head">Articles about us</span>
          <h2 className="home-heading15 section-heading">
            We’re the app on everyone’s lips
          </h2>
        </header>
        <main className="home-cards2">
          <Article rootClassName="article-root-class-name"></Article>
          <Article
            Header="techeu"
            SpecialHeader="eu"
            rootClassName="article-root-class-name"
          ></Article>
          <Article
            Header="sifted/"
            rootClassName="article-root-class-name"
          ></Article>
        </main>
      </section>
      {*/}
      {/* FAQ}
      <section className="home-section16">
        <header className="home-header12">
          <span className="section-head">FAQ</span>
          <h2 className="home-heading16 section-heading">
            Frequently asked questions
          </h2>
        </header>
        <main className="home-accordion">
          <FAQ rootClassName="f-a-q-root-class-name"></FAQ>
        </main>
      </section>
      {*/}
      {/* Last Chance to download an APP :D}
      <section className="home-section18">
        <main className="home-content5">
          <header className="home-header13">
            <h2 className="home-heading17 section-heading">
              Stop searching online for medications and use Planical app!
            </h2>
            <div className="home-buttons2">
              <div className="home-i-o-s button">
                <img
                  alt="image"
                  src="/assets/apple-200w.png"
                  className="home-icon"
                />
                <span className="home-text40">Download for iOS</span>
              </div>
              <div className="home-android button">
                <img
                  alt="image"
                  src="/assets/android-200h.png"
                  className="home-icon1"
                />
                <span className="home-text41">Download for Android</span>
              </div>
            </div>
          </header>
          <img
            alt="image"
            src="/assets/group%201505-1200w.png"
            className="home-image15"
          />
        </main>
      </section>
      {*/}
      <footer className="home-footer">
        <div className="home-content6">
          <main className="home-main-content">
            <div className="home-content7">
              <header className="home-main4">
                <div className="home-header14">
                  <img
                    alt="image"
                    src="/assets/dakáró.svg"
                    className="home-branding"
                  />
                  <span className="home-text42">
                    Minőség, bizalom, tapasztalat
                  </span>
                </div>
                <div className="home-socials">
                  <a
                    href="https://www.facebook.com/Dakarokonyvek"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link"
                  >
                    <Facebook />
                  </a>
                </div>
              </header>
              <header className="home-categories">
                <div className="home-category">
                  <div className="home-header15">
                    <a className="footer-header">Tevékenységeink</a>
                  </div>
                  <div className="home-links">
                    <a onClick={handleFeatureSelection} id='book_publish_link' className="footer-link">Könyvkiadás</a>
                    <a onClick={handleFeatureSelection} id='quality_control_management_link' className="footer-link">Minőségirányítás</a>
                    <a onClick={handleFeatureSelection} id='personal_data_protection_link' className="footer-link">Adatvédelem</a>
                  </div>
                </div>
                <div className="home-category1">
                  <div className="home-header16">
                    <span className="footer-header">Oldal</span>
                  </div>
                  <div className="home-links1">
                    <a onClick={handleModalImpressumOpen} className="footer-link">Impresszum</a>
                    <a onClick={handleModalPrivacyPolicyOpen} className="footer-link">Adatkezelési tájékoztató</a>
                    <a onClick={handleModalContactOpen} className="footer-link">Kapcsolat</a>
                  </div>
                </div>
                <div className="home-category1">
                  <div className="home-header16">
                    <span className="footer-header">Cég</span>
                  </div>
                  <div className="home-links1">
                    {/* <a href="#about-us" className="footer-link">Rólunk</a> */}
                    {/* <a href='#team' className="footer-link">Csapat</a> */}
                    <a onClick={handleScroll} id="news_link" className="footer-link">Hírek</a>
                    <a onClick={handleScroll} id="partners_link" className="footer-link">Partnereink</a>
                    <a onClick={handleScroll} id="career_link" className="footer-link">Karrier</a>
                  </div>
                </div>
              </header>
            </div>
            <section className="home-copyright">
              <span className="home-text56">
                © {new Date().getFullYear()} DAKÁRÓ. Minden jog fenntarvtva.
              </span>
            </section>
          </main>
          {/* SUBSCRIBE }
          <main className="home-subscribe">
            <main className="home-main5">
              <h1 className="home-heading18">Iratkozzon fel hírlevelünkre</h1>
              <div className="home-input-field">
                <input
                  type="email"
                  placeholder="Írja be az email címét"
                  className="home-textinput input"
                />
                <div className="home-buy3 button">
                  <span className="home-text57">-&gt;</span>
                  <span className="home-text58">
                    <span>Feliratkozás</span>
                    <br></br>
                  </span>
                </div>
              </div>
            </main>
            <h1 className="home-notice">
              Feliratkozásával elfogadja a Felhasználási feltételeinket
            </h1>
          </main>
          {*/}
          <section className="home-copyright1">
            <span className="home-text61">
              © {new Date().getFullYear()} DAKÁRÓ. Minden jog fenntarvtva.
            </span>
          </section>
        </div>
      </footer>
      <div>
        <DangerousHTML
          html={`<script>
    /*
Accordion - Code Embed
*/

/* listenForUrlChangesAccordion() makes sure that if you changes pages inside your app,
the Accordions will still work*/

const listenForUrlChangesAccordion = () => {
      let url = location.href;
      document.body.addEventListener(
        "click",
        () => {
          requestAnimationFrame(() => {
            if (url !== location.href) {
              runAccordionCodeEmbed();
              url = location.href;
            }
          });
        },
        true
      );
    };


const runAccordionCodeEmbed = () => {
    const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
    const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
    const accordionIcons = document.querySelectorAll('[data-role="accordion-icon"]'); // All accordion icons

    accordionContents.forEach((accordionContent) => {
        accordionContent.style.display = "none"; //Hides all accordion contents
    });

    accordionContainers.forEach((accordionContainer, index) => {
        accordionContainer.addEventListener("click", () => {
            accordionContents.forEach((accordionContent) => {
            accordionContent.style.display = "none"; //Hides all accordion contents
            });

            accordionIcons.forEach((accordionIcon) => {
                accordionIcon.style.transform = "rotate(0deg)"; // Resets all icon transforms to 0deg (default)
            });

            accordionContents[index].style.display = "flex"; // Shows accordion content
            accordionIcons[index].style.transform = "rotate(180deg)"; // Rotates accordion icon 180deg
        });
    });
}

runAccordionCodeEmbed()
listenForUrlChangesAccordion()

/*
Here's what the above is doing:
    1. Selects all accordion containers, contents, and icons
    2. Hides all accordion contents
    3. Adds an event listener to each accordion container
    4. When an accordion container is clicked, it:
        - Hides all accordion contents
        - Resets all icon transforms to 0deg (default)
        - Checks if this container has class "accordion-open"
            - If it does, it removes class "accordion-open"
            - If it doesn't, it:
                - Removes class "accordion-open" from all containers
                - Adds class "accordion-open" to this container
                - Shows accordion content
                - Rotates accordion icon 180deg
*/
</script>`}
        ></DangerousHTML>
      </div>
    </div>
  )
}

export default Home
