import React, { } from "react";
import PropTypes from "prop-types";

import Mark from "../components/mark";

const ApplicationAppearance = (props) => {
	return (
		<section className="home-section02">
			<header className="home-header01">
				<h2 className="home-text15">Why do you need a mobile medical app?</h2>
				<span className="home-text16">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt.
				</span>
			</header>
			<section className="home-note1">
				<div className="home-image09">
					<img
						alt="image"
						src="/assets/group%201428-1200w.png"
						className="home-image10"
					/>
				</div>
				<div className="home-content2">
					<div className="home-main2">
						<div className="home-caption2">
							<span className="section-head">Tempor incididunt</span>
						</div>
						<div className="home-heading02">
							<h2 className="section-heading">
								We provide compassionate care from start to finish.
							</h2>
							<p className="section-description">
								Sed ut perspiciatis unde omnis iste natus error sit voluptatem
								accusantium doloremque laudantium, totam rem aperiam, eaque
								ipsa quae ab illo inventore veritatis et quasi architecto
								beatae.
							</p>
						</div>
					</div>
					<div className="home-get-started2 button">
						<span className="home-text17">Get started</span>
					</div>
				</div>
			</section>
			<section className="home-note2">
				<div className="home-image11">
					<img
						alt="image"
						src="/assets/group%201449-1200w.png"
						className="home-image12"
					/>
				</div>
				<div className="home-content3">
					<main className="home-main3">
						<header className="home-caption3">
							<span className="home-section04 section-head">
								Tempor incididunt
							</span>
						</header>
						<main className="home-heading04">
							<header className="home-header02">
								<h2 className="section-heading">
									Great care, wherever you are
								</h2>
								<p className="section-description">
									Sed ut perspiciatis unde omnis iste natus error sit
									voluptatem accusantium doloremque laudantium.
								</p>
							</header>
							<div className="home-checkmarks">
								<Mark></Mark>
								<Mark Label="Quis nostrud exercitation ullamco"></Mark>
								<Mark Label="Reprehenderit qui in ea voluptate velit"></Mark>
							</div>
						</main>
					</main>
					<div className="home-get-started3 button">
						<span className="home-text18">Get started</span>
					</div>
				</div>
			</section>
		</section>
	)
}

ApplicationAppearance.defaultProps = {
	application_name: "",
}

ApplicationAppearance.PropTypes = {
	application_name: PropTypes.string,
}

export default ApplicationAppearance
