import React from 'react'
import PropTypes from "prop-types";
import Card from './card';

import './slider.css';
import './partner_card.css';

const Partners = (props) => {
	var partner_varga = {
		name: "Varga Könyvelőiroda",
		web_url: "https://vargakonyveloiroda.hu/",
		image_url: "https://vargakonyveloiroda.hu/wp-content/themes/varga_konyveloiroda/assets/dist/svg/logo.svg",
		image_alt: "varga_konyveloiroda_logo",
		circle_logo: false
	}
	var partner_ebixv = {
		name: "Egyesített Bölcsődék XV. kerület",
		web_url: "https://ebixv.hu/",
		image_url: "https://ebixv.hu/images/logo2.png",
		image_alt: "ebi_xv_logo",
		circle_logo: false
	}
	var partner_rakospalotai_osszevont_ovoda = {
		name: "Rákospalotai Összevont Óvoda",
		web_url: "https://www.rakospalotaiovi.hu/",
		image_url: "https://www.rakospalotaiovi.hu/resources/images/logo_header.jpg",
		image_alt: "rakospalotai_osszevont_ovoda_logo",
		circle_logo: true
	}
	var partner_ujpalotai_osszevont_ovoda = {
		name: "Újpalotai Összevont Óvoda",
		web_url: "https://ujpalotaiovi.hu/",
		image_url: "https://ujpalotaiovi.hu/resources/images/logo_header.jpg",
		image_alt: "ujpalotai_osszevont_ovoda_logo",
		circle_logo: true
	}
	return (
		<section className="home-section05" id={props.id}>
			<h2 className="home-text02">Partnereink</h2>
			<main className="home-cards">
				<PartnerCard {...partner_varga} />
				<PartnerCard {...partner_ebixv} />
				<PartnerCard {...partner_rakospalotai_osszevont_ovoda} />
				<PartnerCard {...partner_ujpalotai_osszevont_ovoda} />
			</main>
		</section>
	)
}

const PartnerCard = (props) => {
	return (
		<a className="partner-card" title={props.name} href={props.web_url} target="_blank">
			<img className={`${props.name == "Varga Könyvelőiroda" ? 'varga_konyveloiroda_logo_svg_dark' : ''} ${props.circle_logo == true ? 'circle_logo' : ''}`} src={props.image_url} alt={props.image_alt} />
			<p className={'partner-name'}>{props.name}</p>
		</a>
	)
}

PartnerCard.propTypes = {
	name: PropTypes.string,
	web_url: PropTypes.string,
	image_url: PropTypes.string,
	image_alt: PropTypes.string,
	circle_logo: PropTypes.bool,
}

Partners.propTypes = {
	id: PropTypes.string
}

export default Partners;