import React, { useState } from 'react'

import DangerousHTML from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './navbar.css'
import { handleScroll } from '../views/home'

const Navbar = (props) => {
  const [isMobile, setIsMobile] = useState(false)
  return (
    <nav className={`navbar-navbar ${props.rootClassName} `}>
      <img
        src={props.image_src2}
        alt={props.image_alt2}
        className="navbar-image"
      />
      <img
        src={props.image_src2}
        alt={props.image_alt2}
        className="navbar-image navbar-dakaro-logo-reflection"
      />
      <div className="navbar-nav-content">
        <div className="navbar-nav-links">
          <a onClick={handleScroll} id="news_link" className="nav-link">Híreink</a>
          <a onClick={handleScroll} id="activities_link" className="nav-link">Tevékenységeink</a>
          {/* <a href='#contacts' className="nav-link">Rólunk</a> */}
          <a onClick={handleScroll} id="partners_link" className="nav-link">Partnereink</a>
        </div>
        <div className="get-started navbar-get-started">
          <a href="/shop" className="navbar-text2">Könyv Katalógus</a>
        </div>
        <div id="open-mobile-menu" className="navbar-hamburger get-started">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="navbar-image1"
          />
        </div>
      </div>
      <div id="mobile-menu" className="navbar-mobile-menu">
        <div className="navbar-branding">
          <img
            alt={props.image_alt1}
            src={props.image_src1}
            className="navbar-image2"
          />
          <div id="close-mobile-menu" className="navbar-container">
            <svg viewBox="0 0 1024 1024" className="navbar-icon">
              <path
                d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
        <div className="navbar-nav-links1">
          <a onClick={handleScroll} id="activities_link" className="nav-link">Tevékenységeink</a>
          {/* <a href='#contacts' className="nav-link">Rólunk</a> */}
          <a onClick={handleScroll} id="partners_link" className="nav-link">Partnereink</a>
        </div>
        <div className="get-started">
          <a href="/shop" className="navbar-text2">Könyv Katalógus</a>
        </div>
      </div>
      <div className="">
        <DangerousHTML
          html={`<script>
    /*
Mobile menu - Code Embed
*/

/* listenForUrlChangesMobileMenu() makes sure that if you changes pages inside your app, 
the mobile menu will still work*/

const listenForUrlChangesMobileMenu = () => {
    let url = location.href;
    document.body.addEventListener("click", () => {
        requestAnimationFrame(() => {
            if (url !== location.href) {
                runMobileMenuCodeEmbed();
                url = location.href;
            }
        });
    },
    true
    );
};

const runMobileMenuCodeEmbed = () => {
    // Mobile menu
    const mobileMenu = document.querySelector("#mobile-menu")

    // Buttons
    const closeButton = document.querySelector("#close-mobile-menu")
    const openButton = document.querySelector("#open-mobile-menu")

    // On openButton click, set the mobileMenu position left to -100vw
    openButton && openButton.addEventListener("click", function() {
        mobileMenu.style.transform = "translateX(0%)"
    })

    // On closeButton click, set the mobileMenu position to 0vw
    closeButton && closeButton.addEventListener("click", function() {
        mobileMenu.style.transform = "translateX(100%)"
    })
}

runMobileMenuCodeEmbed()
listenForUrlChangesMobileMenu()
</script>`}
          className=""
        ></DangerousHTML>
      </div>
    </nav>
  )
}

Navbar.defaultProps = {
  image_alt: 'image',
  image_src1: '/assets/dak%C3%A1r%C3%B3.svg',
  image_src: '/assets/hamburger-200h.png',
  image_alt1: 'image',
  rootClassName: '',
  image_src2: '/assets/dak%C3%A1r%C3%B3.svg',
  image_alt2: 'image',
}

Navbar.propTypes = {
  image_alt: PropTypes.string,
  image_src1: PropTypes.string,
  image_src: PropTypes.string,
  image_alt1: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src2: PropTypes.string,
  image_alt2: PropTypes.string,
}

export default Navbar
