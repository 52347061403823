import React from 'react';

import "./products.css"
import ProductCard from './product_card';

const Products = () => {
	return (
		<div className='products'>
		<ProductCard
			bookCover="https://books.google.com/books/publisher/content/images/frontcover/nsusEAAAQBAJ"
			bookTitle="Átverés"
			bookAuthor="Kathy Schranko"
			productURL="https://dakaro.hu"
			googleProductURL="https://play.google.com/store/books/details?id=nsusEAAAQBAJ" />
		<ProductCard
			bookCover="https://books.google.com/books/publisher/content/images/frontcover/X--hEAAAQBAJ"
			bookTitle="Távoli fény"
			bookAuthor="Kathy Schranko"
			productURL="https://dakaro.hu"
			googleProductURL="https://play.google.com/store/books/details?id=X--hEAAAQBAJ" />
			{/*<ProductCard
				bookCover=""
				bookTitle=""
				bookAuthor=""
				googleUrl="" /> */}
		</div>
	)
}

export default Products
