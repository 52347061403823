import React, { useState } from "react";
import PropTypes from "prop-types";

import { AutoStories, Lock, TrendingUp } from '@mui/icons-material';
import Mark from "../components/mark";

import './activities.css';
import Products from "./products";

export const ActivitiesType = {
	BOOK_PUBLISHING: 0,
	QUALITY_MANAGEMENT_SYSTEM: 1,
	PERSONAL_DATA_PROTECTION: 2,
}

const Activities = (props) => {
	const [selectedFeature, setSelectedFeature] = useState(props.selectedFeature)

	return (
		<section className="home-section01" id={props.id}>
			<h2 className="home-text02">Tevékenységeink</h2>
			<div className="home-features">
				<header className={`feature ${selectedFeature == 0 ? "feature-active" : ""}`} onClick={() => {setSelectedFeature(0)}}>
					<div className="feature-lead-icon">
						<AutoStories />
					</div>
					<div className="feature-body">
						<h3 className="home-text03">Könyvkiadás</h3>
						<p className="home-text04">Szerkesztés, Kiadás, Terjesztés, Marketing</p>
					</div>
				</header>
				<header className={`feature ${selectedFeature == 1 ? "feature-active" : ""}`} onClick={() => {setSelectedFeature(1)}}>
					<div className="feature-lead-icon">
						<TrendingUp />
					</div>
					<div className="feature-body">
						<h3 className="home-text05">Minőségirányítás (MIR)</h3>
						<p className="home-text06">ISO 9001 szabvány alapján tanácsadás</p>
					</div>
				</header>
				<header className={`feature ${selectedFeature == 2 ? "feature-active" : ""}`}  onClick={() => {setSelectedFeature(2)}}>
					<div className="feature-lead-icon">
						<Lock />
					</div>
					<div className="feature-body">
						<h3 className="home-text07">Adatvédelem (GDPR)</h3>
						<p className="home-text08">Személyes adatvédelem tanácsadás, DPO</p>
					</div>
				</header>
			</div>


			{/* Book Publish */}
			<div className={`home-note ${selectedFeature == 0 ? "home-note-active" : "home-note-disable"}`}>
				<div className="home-content1">
					<main className="home-main1">
						<h2 className="home-heading01">
							Könyvkiadás
						</h2>
						<p className="home-paragraph">
							<span>
								Könyveinket első sorban elektronikus formában adjuk ki és terjesszük, hiszen így könnyedén magával viheti bárhová kedvenc olvasmányait. Ezzel könnyítve önmagán és a természeten. <br />
								<br />
								Könyveink több formátumban is elérhetőek. E-book-ként (.epub, .pdf, .mobi) jelenleg csak a Google kínálatában. 
							</span>
							<br></br>
							<br></br>
							<span>
								Tekintse meg újonnan megjelent kiadásainkat, vagy a teljes könyv katalógusunkat!
							</span>
							<br></br>
						</p>
					</main>
					<div className="home-get-started button">
						<span className="home-text">Könyv katalógus</span>
					</div>
				</div>
				<div className="home-image07 modification">
					<h5 className="home-heading01 modification">
						Újonnan megjelentek:
					</h5>
					<Products />
				</div>
			</div>


			{/* Quality Control */}
			<div className={`home-note ${selectedFeature == 1 ? "home-note-active" : "home-note-disable"}`}>
				<div className="home-content1">
					<main className="home-main1">
						<h2 className="home-heading01">
							Minőségirányítás
						</h2>
						<p className="home-paragraph">
							<span>
							Vállaljuk a Minőségirányítási rendszer (MIR) kiépítését, a folyamatok dokumentálását, tájékoztatást a dolgozók számára, tudásuk visszamérését, szabályzatok aktualizálását, helyszíni auditok elvégzését, szükség szerint Minőségügyi vezetői tevékenységet. Belső kontroll környezet, ellenőrzési nyomvonalak kialakítása, felelős felkészítése.  Rendszeres személyes találkozásokkor tanácsadást végzünk amellyel segítjük az Intézmény/Társaság felsővezetésének objektív döntéshozatalát.
							</span>
							<br/>
							<br/>
							<div className="home-checkmarks">
								<Mark Label="Minőségirányítási (ISO 9001) rendszer építése, működtetése, fejlesztése"/>
								<Mark Label="Belső kontroll környezet kialakítása" />
							</div>
							<br></br>
						</p>
					</main>
					<div className="home-get-started button">
						<span className="home-text">További részletek</span>
					</div>
				</div>
				<div className="home-image07 modification">
					<img
						alt="image"
						src="/assets/"
						className="home-image08b"
					/>

				</div>
			</div>


			{/* Data Protection */}
			<div className={`home-note ${selectedFeature == 2 ? "home-note-active" : "home-note-disable"}`}>
				<div className="home-image07">
					<img
						alt="image"
						src="/assets/gdpr.png"
						className="home-image08"
					/>
				</div>
				<div className="home-content1">
					<main className="home-main1">
						<h2 className="home-heading01">
							Adatvédelem
						</h2>
						<p className="home-paragraph">
							<span>
							Vállaljuk a személyes adatok védelmi rendszerének kiépítését, a folyamatok dokumentálását, tájékoztatást a dolgozók számára, tudásuk visszamérését, szabályzatok aktualizálását, helyszíni auditok elvégzését,  DPO képviseletet. Rendszeres személyes találkozásokkor tanácsadást végzünk amellyel segítjük az Intézmény/Társaság felsővezetésének objektív döntéshozatalát.
							</span>
							<br/>
							<br/>
							<div className="home-checkmarks">
								<Mark Label="Személyes adatok védelme (GDPR) rendszer építése, működtetése, fejlesztése."/>
								<Mark Label="Adatvédelmi tisztviselő (DPO) képviselet biztosítása" />
							</div>
							<br></br>
						</p>
					</main>
					<div className="home-get-started button">
						<span className="home-text">További részletek</span>
					</div>
				</div>
			</div>
		</section>
	);
};

Activities.propTypes = {
	selectedFeature: PropTypes.number,
	id: PropTypes.string,
}

export default Activities
